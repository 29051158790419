// auth0Helpers.js
import axios from "axios";

export const getManagementApiToken = async () => {
  const url = `https://${process.env.GATSBY_AUTH0_DOMAIN}/oauth/token`;
  const payload = {
    client_id: process.env.GATSBY_AUTH0_MGMT_CLIENT_ID,
    client_secret: process.env.GATSBY_AUTH0_MGMT_CLIENT_SECRET,
    audience: `https://dev-e2-juiwx.us.auth0.com/api/v2/`,
    grant_type: "client_credentials",
  };

  try {
    const response = await axios.post(url, payload);
    return response.data.access_token;
  } catch (error) {
    console.error("Error fetching Management API token:", error);
    throw error;
  }
};

export const generateStrongPassword = () => {
  const length = 12;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};

export const checkIfUserExists = async (email, token) => {
  if (!email || !token) {
    throw new Error("Email and token are required to check user existence.");
  }
  const managementApiUrl = `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users-by-email?email=${email}`;

 try {
    const response = await fetch(managementApiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch user: ${response.statusText}`);
    }

    const users = await response.json();

    if (users.length > 0) {
      console.log("✅ User exists in Auth0:", email);
      return users[0].user_id; // Return the userId
    } else {
      console.log("❌ User does not exist in Auth0:", email);
      return null; // Return null if the user does not exist
    }
  } catch (error) {
    console.error("❌ Error checking user existence:", error);
    throw error;
  }
};

export const createAuth0User = async (email, firstName, lastName, token) => {
  const tempPassword = generateStrongPassword();
  const newUserPayload = {
    email,
    given_name: firstName,
    family_name: lastName,
    name: `${firstName} ${lastName}`,
    connection: "Username-Password-Authentication",
    password: tempPassword,
    email_verified: false,
  };

  const response = await fetch(
    `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUserPayload),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message);
  }

  return response.json();
};

export const assignAuth0Role = async (userId, role, token) => {
  const response = await fetch(
    `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users/${userId}/roles`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ roles: [role] }),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message);
  }

  return response.json();
};