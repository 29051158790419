// PasswordReset.js
import React, { useState } from "react";

export const PasswordReset = ({ email, isSendingReset, onReset }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <p>Having trouble logging in? Reset your password below:</p>
      <button
        onClick={onReset}
        disabled={isSendingReset}
        style={{
          backgroundColor: "#007BFF",
          color: "#fff",
          padding: "10px 15px",
          border: "none",
          cursor: isSendingReset ? "not-allowed" : "pointer",
        }}
      >
        {isSendingReset ? "Sending Reset Email..." : "Forgot Password?"}
      </button>
    </div>
  );
};